<template>
	<main :style="`width: calc(100% - ${sideBarWidth}px) !important; transform: translateX(${sideBarWidth}px);`">
		<Toast
			class="mt-3"
			:visible="connectionError"
			variant="error"
		/>
		<div :class="{ 'content-offset': connectionError, 'content-no-offset': !connectionError }">
			<b-row class="justify-content-evenly m-2" >
				<div class="dashboard-maquina" v-for="maquina in maquinas" :key="maquina.id">
					<Machine
						:machine="maquina"
						:loading="loading"
						:connectionError="connectionError"
					/>
				</div>
			</b-row>
		</div>
	</main>
</template>

<script>
	import { mapState } from "vuex";

	import { MaquinasService } from "@/services/maquinas";
	import Machine from "../components/Machine";
	import Toast from "@/components/Toast";

	const UPDATE_TIMEOUT = 1 * 1000;

	export default {
		components: {
			Machine,
			Toast
		},

		data () {
			return {
				maquinas: [],
				maquinasService: new MaquinasService(),
				updateInterval: null,
				loading: true,
				connectionError: true
			};
		},

		computed: mapState(["sideBarWidth"]),

		async mounted () {
			this.update();
			this.updateInterval = setInterval(() => { this.update(); }, UPDATE_TIMEOUT);
		},

		beforeDestroy () {
			clearInterval(this.updateInterval);
		},

		methods: {
			async update () {
				try {
					this.loading = true;
					this.maquinas = await this.maquinasService.getAllMachinesIncludeOrdemProducao();
					this.connectionError = false;
					this.loading = false;
				} catch (err) {
					this.connectionError = true;
					console.error(err);
				}
			}
		}
	};
</script>

<style scoped>
	main {
		transition: all 0.6s;
		overflow-x: hidden;
	}

	.transform-animation {
		transition: all 0.6s;
	}

	.content-offset {
		margin-top: 3rem;
		transition: all 0.6s;
	}

	.content-no-offset {
		transition: all 2.6s;
	}
</style>
