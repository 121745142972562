<template>
	<div @click="openSinoptico()">
		<div
			class="machine-card transform-animation"
			v-bind:class="openFooterCard ? 'card-focus' : ''"
			@mouseover="openFooter()" @mouseleave="closeFooter()"
		>
			<div class="ordem-content">
				<span
					class="ordem-title"
					v-bind:class="machine.semOrdemProducao ? 'footer-col-dark' : ''"
				>
					Ordem de Produção:
				</span>
				<span
					class="ordem-producao"
					v-bind:class="classOrdemProducaoNome"
					:title="machine.ordensProducao.nome_processo"
				>
					{{ machine.ordensProducao.nome_processo }}
					<CircleIcon
						v-if="machine.semOrdemProducao"
						class="circleIcon"
					/>
				</span>
			</div>
			<div>
				<div :title="machine.nome" class="injetora-content">
					<span class="injetora-nome">
						{{ machine.nome }}
					</span>
				</div>
				<div class="machine-body">
					<div
						v-if="(machine.status === 'DOSANDO' || machine.status === 'COMPLETANDO') && !machine.semOrdemProducao"
						class="mr-2 machine-status machine-status-em-ciclo"
					>
						<span class="text-center">
							{{ machine.status }}
						</span>
					</div>
					<div
						v-else-if="machine.status.substring(0, 6) === 'PARADA' && !machine.semOrdemProducao"
						class="mr-2 machine-status machine-status-parada"
					>
						<span class="text-center">
							PARADA
						</span>
					</div>
					<div
						v-else-if="machine.status === 'CICLO_EXCEDIDO' && !machine.semOrdemProducao"
						class="mr-2 machine-status machine-status-ciclo-excedido"
					>
						<span class="text-center">
							CICLO EXCEDIDO
						</span>
					</div>
					<div v-else class="mr-2 machine-status machine-status-offline"
						v-bind:class="machine.semOrdemProducao ? 'footer-col-light' : 'offline-with-ordem-producao'"
					>
						<span class="text-center">
							{{ machine.status }}
						</span>
					</div>
					<TimeStatus
						class="machine-time-status machine-text"
						:colorNumbers="'machine-text-dash'"
						:machine="machine"
						:loading="loading"
						:stopStopwatch="connectionError"
					/>
				</div>
			</div>
		</div>
		<div
			class="transform-animation footer-card transform-animation"
			v-bind:class="openFooterCard ? 'card-focus' : ''"
			@mouseover="openFooter()" @mouseleave="closeFooter()"
		>
			<span
				class="transform-animation footer-content footer-col-dark border-radius-lb"
				v-bind:class="openFooterCard ? 'intensive-col-dark' : ''"
			>
				<br/>Tempo Estimado<br/>
				<span
					v-if="!machine.semOrdemProducao"
				>
					{{ tempoEstimado }}
				</span>
				<span v-else>
					--:--:--
				</span>
			</span>
			<span
				class="transform-animation footer-content footer-col-light"
				v-bind:class="openFooterCard ? 'intensive-col-light' : ''"
			>
				<br/>Tempo de Ciclo<br/>
				<span
					v-if="!machine.semOrdemProducao"
				>
					{{ tempoCiclo }} seg
				</span>
				<span v-else>
					-,--- seg
				</span>
			</span>
			<span
				class="transform-animation footer-content footer-col-dark border-radius-rb"
				v-bind:class="openFooterCard ? 'intensive-col-dark' : ''"
			>
				<br/>Tempo de Dosagem<br/>
				<span
					v-if="!machine.semOrdemProducao"
				>
					{{ tempoDosagem }} seg
				</span>
				<span v-else>
					-,--- seg
				</span>
			</span>
		</div>
	</div>
</template>

<script>
	import TimeStatus from "./TimeStatus.vue";
	import { formatTime } from "../helpers/common";
	import { CircleIcon } from "vue-feather-icons";

	export default {
		name: "Machine",

		components: { TimeStatus, CircleIcon },

		props: {
			machine: {
				type: Object,
				required: true
			},
			loading: {
				type: Boolean,
				default: true
			},
			showBackButton: {
				type: Boolean,
				default: true
			},
			connectionError: {
				type: Boolean,
				default: false
			}
		},

		data () {
			return {
				openFooterCard: false,
				tempoEstimado: null,
				tempoCiclo: null,
				tempoDosagem: null,
				classOrdemProducaoNome: ""
			};
		},

		watch: {
			"machine.ordensProducao.tempoCicloTeorico": "setTempoCiclo",
			"machine.ordensProducao.tempoDosagemTeorico": "setTempoDosagem",
			"machine.ordensProducao.batidas": "getTempoEstimado",
			"machine.status": "setClassOrdemProducaoNome"
		},

		mounted () {
			this.getTempoEstimado();
			this.setTempoCiclo();
			this.setTempoDosagem();
			this.setClassOrdemProducaoNome();
		},

		methods: {
			setTempoCiclo () {
				this.tempoCiclo = parseFloat(this.machine.ordensProducao.tempoCicloTeorico / 1000).toFixed(3).replace(".", ",");
				this.getTempoEstimado();
			},

			setTempoDosagem () {
				this.tempoDosagem = parseFloat(this.machine.ordensProducao.tempoDosagemTeorico / 1000).toFixed(3).replace(".", ",");
			},

			getTempoEstimado () {
				this.tempoEstimado = formatTime(this.machine.ordensProducao.batidas * (this.machine.ordensProducao.tempoCicloTeorico / 1000));
			},

			openFooter () {
				this.openFooterCard = true;
			},

			closeFooter () {
				this.openFooterCard = false;
			},

			openSinoptico () {
				const { id } = this.machine;
				this.$router.options.routes[1].props.id = id;
				this.$router.push({ path: "/sinoptico" });
			},

			setClassOrdemProducaoNome () {
				const semOrdemProducao = this.machine.semOrdemProducao || false;
				this.classOrdemProducaoNome = semOrdemProducao ? "sem-ordem-producao-nome" : "offline-with-ordem-producao";
			}
		}
	};
</script>

<style scoped>
	.machine-card {
		position: relative;
		width: 24rem;
		margin: 2rem 0.5rem 0 0.5rem;
		height: 10rem;
		line-height: 1.25rem;
		background: #FFFFFF;
		border-radius: 0.5rem;
		z-index: 1000;
		cursor: pointer;
	}

	.ordem-content {
		display: flex;
		height: 2rem;
	}

	.ordem-title {
		font-style: normal;
		width: 32%;
		font-weight: 700;
		font-size: 0.90rem;
		display: flex;
		align-items: center;
		padding-left: 5px;
		color: #FFFFFF;
		background: #20B2AA;
		border-radius: 10px 0px 0px 0px;
	}

	.ordem-producao {
		width: 68%;
		font-style: normal;
		font-weight: 700;
		font-size: 1.3rem;
		color: #FFFFFF;
		padding: .40rem 5px;
		border-radius: 0 0.5rem 0 0;
		padding-right: 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.transform-animation {
		transition: all 0.6s;
	}

	.footer-tittle {
		padding-top: 0.5rem;
	}

	.footer-content {
		margin-top: -0.3rem;
		padding-bottom: 0.3rem;
	}

	.footer-card {
		position: relative;
		transform: translateY(-46%);
		z-index: 1;
		margin: -0.7rem 0.5rem 0 0.5rem;
		padding-top: 0.5rem;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		height: 4.5rem;
		width: 24rem;
		border-radius: 0 0 0.5rem 0.5rem;
		text-align: center;
		align-content: space-between;
		box-shadow: 0 0.25rem 4px #D9D9D9;
		font-weight: 700;
		font-size: 0.90rem;
		cursor: pointer;
	}

	.card-focus {
		transform: scale(1.02, 1.02);
	}

	.footer-col-light {
		background: #BABABA;
		color: #FFFFFF;
	}

	.intensive-col-light {
		background: #AEAEAE;
		color: #FFFFFF;
	}

	.footer-col-dark {
		background: #AEAEAE;
		color: #FFFFFF;
	}

	.intensive-col-dark {
		background: #696969;
		color: #FFFFFF;
	}

	.border-radius-lb {
		border-radius: 0 0 0 0.5rem;
	}

	.border-radius-rb {
		border-radius: 0 0 0.5rem;
	}

	.injetora-content {
		display: flex;
	}

	.injetora-nome {
		margin-top: 0.2rem;
		padding: 1rem;
		font-style: normal;
		font-weight: 900;
		font-size: 2.25rem;
		letter-spacing: -0.05em;
		color: #3A9BD3;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.machine-body {
		width: 100%;
		display: flex;
		padding: 1rem;
		justify-content: space-between;
	}

	.machine-status {
		width: 10rem;
		height: 2.5rem;
		display: flex;
		padding: 0.65rem;
		justify-content: center;
		align-items: center;
		font-style: normal;
		font-weight: 900;
		font-size: 1.20rem;
		color: #FFFFFF;
		border-radius: 0.5rem;
	}

	.machine-status-em-ciclo {
		background: #28A745;
	}

	.machine-status-ciclo-excedido {
		background: #FFAF20;
	}

	.machine-status-offline {
		background: #AEAEAE;
	}

	.machine-status-parada {
		background: #C12332;
	}

	.machine-time-status {
		padding-top: 0.60rem;
		text-align: right;
	}

	.circleIcon {
		float: right;
		color: #C12332;
		background: #C12332;
		border-radius: 50%;
		height: .85rem;
		width: .85rem;
		margin-top: 3px;
		margin-right: 3px;
	}

	.sem-ordem-producao-nome {
		background: #CED4DA;
	}

	.offline-with-ordem-producao {
		background: #696969;
	}

	.machine-text {
		font-style: normal;
		font-variant-numeric: tabular-nums;
		font-weight: 900;
		font-size: 2.5rem;
	}
</style>
